<template>
  <div>
    <p>Add a existing user to this supplier, or <a href="#" @click.prevent="invite = !invite">Invite a new user</a></p>
    <div v-if="invite === false">
      <validation-observer v-slot="{ invalid }" class="pt-3">
        <form @submit.prevent="submitUser">
          <validation-provider v-if="!connection" name="Find Users" v-slot="context" rules="required">
            <b-form-group label="User">
              <multi-select
                v-model="user"
                :options="users"
                placeholder="Pick a user"
                label="email"
                @open="findUser"
                @search-change="findUser"
              />
              <small v-if="error" class="text-danger">{{ error }}</small>
            </b-form-group>
          </validation-provider>

          <template v-else>
            <b-form-checkbox-group v-model="selectedPermissions" :options="permissions" stacked />
          </template>

          <hr />
          <b-row align-h="end">
            <b-col cols="auto">
              <b-button variant="primary" :disabled="isLoading" type="submit">
                {{ !connection ? 'Add User' : 'Sync Permissions' }}
                <b-spinner v-show="isLoading" class="ml-2" small label="Loading" />
              </b-button>
            </b-col>
          </b-row>
        </form>
      </validation-observer>
    </div>
    <div v-else>
      <b-form-group label="Invite Supplier Email">
        <b-input v-model="inviteEmail" />
        <small v-if="error" class="text-danger">{{ error }}</small>
      </b-form-group>
      <b-button @click="inviteUser()" :disabled="isLoading">
          <b-spinner v-show="isLoading" class="ml-2" small label="Loading" />
          Invite
      </b-button>
    </div>
  </div>
</template>

<script>
import 'vue-multiselect/dist/vue-multiselect.min.css'

import { users as usersApi, suppliers as suppliersApi, roles as roleApi } from '@/api'
import { getValidationState } from './page-helpers'
import MultiSelect from 'vue-multiselect'

export default {
  name: 'SupplierUsersForm',
  components: { MultiSelect },
  props: { connection: { type: Object } },
  data() {
    return {
        user: '',
        users: [],
        permissions: [],
        selectedPermissions: [],
        invite: false,
        inviteEmail: '',
        error: null,
        isLoading: false,
    }
  },
  async mounted() {
    if (this.connection) {
      const roles = await roleApi.all()
      this.permissions = roles.data
        .find(role => role.name === 'Supplier')
        .permissions.map(permission => ({ value: permission.id, text: permission.name }))
      this.selectedPermissions = this.connection.user.permissions.map(permission => {
        return this.permissions.find(p => p.text === permission).value
      })
    }
  },
  methods: {
    getValidationState,
    submitUser() {
      if (this.connection) {
        this.isLoading = true
        this.syncUserPermissions()
      } else {
        this.inviteEmail = this.user.email
        this.inviteUser()
      }
    },
    async syncUserPermissions() {
      const permissions = usersApi.permissions(this.connection.user.id)
      await permissions.sync({ data: this.selectedPermissions })
      this.$emit('updated')
      this.isLoading = false
    },
    async inviteUser() {
      this.isLoading = true
      try {
          await suppliersApi.invite(this.$route.params.id, {
              email: this.inviteEmail,
              invite_new: this.invite
          })
          this.$bvToast.toast(`User invited.`, {
              title: 'User invited',
              variant: 'primary',
          })
          this.$emit('added')
        } catch (error) {
          this.error = error.message
        }
        this.isLoading = false
    },
    async findUser(email = '') {
      const users = await usersApi.all({ filter: { email } })

      this.users = users.data()
    },
  },
}
</script>
