<template>
  <div id="content" class="app-content">
    <!-- BEGIN container -->
    <b-container>
      <!-- BEGIN row -->
      <b-row class="justify-content-center">
        <!-- BEGIN col-10 -->
        <b-col xl="10">
          <!-- BEGIN row -->
          <b-row>
            <!-- BEGIN col-9 -->
            <b-col xl="9">
              <b-breadcrumb>
                <b-breadcrumb-item :to="{ name: 'suppliers' }">
                  SUPPLIERS
                </b-breadcrumb-item>
                <b-breadcrumb-item active>
                  {{ breadcrumbTitle }}
                </b-breadcrumb-item>
              </b-breadcrumb>

              <b-tabs>
                <b-tab title="Booking" @click="tabGoTo({ name: 'suppliers.edit', params: { id: $route.params.id } })">
                  <!-- page header -->
                  <h3 class="page-header pt-3">{{ pageTitle }}</h3>
                  <hr />

                  <validation-observer ref="supplierForm">
                    <form @submit.prevent="submitFormData">
                      <h4><i class="far fa-heading fa-fw"></i> Booking Details</h4>
                      <b-card>
                        <validation-provider vid="booking_name" name="Booking Name" v-slot="context" rules="required">
                          <b-form-group label="Name">
                            <b-form-input
                              id="formNameField"
                              placeholder="e.g Youwish Kundeservice"
                              v-model="booking_name"
                              :state="getValidationState(context)"
                              aria-describedby="formNameFieldError"
                              v-bind="context.ariaInput"
                            />

                            <b-form-invalid-feedback id="formNameFieldError" v-bind="context.ariaMsg">
                              {{ context.errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>

                        <validation-provider vid="booking_phone" name="Phone Number" v-slot="context">
                          <b-form-group label="Phone Number">
                            <b-form-input
                              id="formBookingPhoneNumber"
                              placeholder="e.g 935 75 247"
                              v-model="booking_phone"
                              :state="getValidationState(context)"
                              aria-describedby="formBookingPhoneNumberError"
                              v-bind="context.ariaInput"
                            />

                            <b-form-invalid-feedback id="formBookingPhoneNumberError" v-bind="context.ariaMsg">
                              {{ context.errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>

                        <validation-provider
                          vid="booking_phone_time"
                          name="Phone Time"
                          v-slot="context"
                        >
                          <b-form-group label="Phone Time">
                            <b-form-input
                              id="formBookingFormTime"
                              placeholder="e.g 08:00 - 19:00"
                              v-model="booking_phone_time"
                              :state="getValidationState(context)"
                              aria-describedby="formBookingFormTimeError"
                              v-bind="context.ariaInput"
                            />

                            <b-form-invalid-feedback id="formBookingFormTimeError" v-bind="context.ariaMsg">
                              {{ context.errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>

                        <validation-provider vid="booking_email" name="Email" v-slot="context">
                          <b-form-group label="Email">
                            <b-form-input
                              id="formBookingEmail"
                              placeholder="e.g booking@youwish.no"
                              v-model="booking_email"
                              :state="getValidationState(context)"
                              aria-describedby="formBookingEmailError"
                              v-bind="context.ariaInput"
                            />

                            <b-form-invalid-feedback id="formBookingEmailError" v-bind="context.ariaMsg">
                              {{ context.errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>

                        <validation-provider vid="booking_address" name="Address" v-slot="context">
                          <b-form-group label="Address">
                            <b-form-textarea
                              id="formBookingAddress"
                              v-model="booking_address"
                              :state="getValidationState(context)"
                              aria-describedby="formBookingAddressError"
                              v-bind="context.ariaInput"
                            />

                            <b-form-invalid-feedback id="formBookingAddressError" v-bind="context.ariaMsg">
                              {{ context.errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>

                        <validation-provider
                          vid="booking_postal_code"
                          name="Post Code"
                          v-slot="context"
                        >
                          <b-form-group label="Post Code">
                            <b-form-input
                              id="formBookingPostCode"
                              placeholder="e.g 1234"
                              v-model="booking_postal_code"
                              :state="getValidationState(context)"
                              aria-describedby="formBookingPostCodeError"
                              v-bind="context.ariaInput"
                            />

                            <b-form-invalid-feedback id="formBookingPostCodeError" v-bind="context.ariaMsg">
                              {{ context.errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>

                        <validation-provider vid="booking_city" name="City" v-slot="context">
                          <b-form-group label="City">
                            <b-form-input
                              id="formBookingCity"
                              placeholder="e.g 1234"
                              v-model="booking_city"
                              :state="getValidationState(context)"
                              aria-describedby="formBookingCityError"
                              v-bind="context.ariaInput"
                            />

                            <b-form-invalid-feedback id="formBookingCityError" v-bind="context.ariaMsg">
                              {{ context.errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>

                        <validation-provider vid="booking_url" name="Booking URL" v-slot="context">
                          <b-form-group label="Booking URL">
                            <b-form-input
                              id="formBookingURL"
                              placeholder="e.g http://youwish.no/booking"
                              v-model="booking_url"
                              :state="getValidationState(context)"
                              aria-describedby="formBookingURLError"
                              v-bind="context.ariaInput"
                            />

                            <b-form-invalid-feedback id="formBookingURLError" v-bind="context.ariaMsg">
                              {{ context.errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>

                        <validation-provider vid="booking_website_url" name="Website URL" v-slot="context">
                          <b-form-group label="Website URL">
                            <b-form-input
                              id="formBookingWebsiteURL"
                              placeholder="e.g https://youwish.no"
                              v-model="booking_website_url"
                              :state="getValidationState(context)"
                              aria-describedby="formBookingWebsiteURLError"
                              v-bind="context.ariaInput"
                            />

                            <b-form-invalid-feedback id="formBookingWebsiteURLError" v-bind="context.ariaMsg">
                              {{ context.errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                      </b-card>

                      <hr />

                      <h4><i class="far fa-heading fa-fw"></i> Contact</h4>
                      <b-card>
                        <validation-provider vid="contact_name" name="Name" v-slot="context" rules="required">
                          <b-form-group label="Name">
                            <b-form-input
                              id="formContactName"
                              placeholder="e.g Thorfinn Karlsefni"
                              v-model="contact_name"
                              :state="getValidationState(context)"
                              aria-describedby="formContactNameError"
                              v-bind="context.ariaInput"
                            />

                            <b-form-invalid-feedback id="formContactNameError" v-bind="context.ariaMsg">
                              {{ context.errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>

                        <validation-provider vid="contact_email" name="Email" v-slot="context" rules="required">
                          <b-form-group label="Email">
                            <b-form-input
                              id="formContactEmail"
                              placeholder="e.g thorfinn@karlsefni.com"
                              v-model="contact_email"
                              :state="getValidationState(context)"
                              aria-describedby="formContactEmailError"
                              v-bind="context.ariaInput"
                            />

                            <b-form-invalid-feedback id="formContactEmailError" v-bind="context.ariaMsg">
                              {{ context.errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>

                        <validation-provider vid="contact_phone" name="Phone" v-slot="context">
                          <b-form-group label="Phone">
                            <b-form-input
                              id="formContactPhone"
                              placeholder="e.g 935 75 247"
                              v-model="contact_phone"
                              :state="getValidationState(context)"
                              aria-describedby="formContactPhoneError"
                              v-bind="context.ariaInput"
                            />

                            <b-form-invalid-feedback id="formContactPhoneError" v-bind="context.ariaMsg">
                              {{ context.errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                      </b-card>

                      <hr />

                      <h4><i class="far fa-heading fa-fw"></i> Notifications</h4>
                      <b-card>
                        <validation-provider
                          vid="notify_booking_email"
                          name="Booking notifications email"
                          v-slot="context"
                        >
                          <b-form-group label="Booking notifications email">
                            <b-form-input
                              id="formNotifyBookingEmail"
                              placeholder="e.g thorfinn@karlsefni.com"
                              v-model="notify_booking_email"
                              :state="getValidationState(context)"
                              aria-describedby="formNotifyBookingEmailError"
                              v-bind="context.ariaInput"
                            />

                            <b-form-invalid-feedback id="formNotifyBookingEmailError" v-bind="context.ariaMsg">
                              {{ context.errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>

                        <validation-provider
                          vid="notify_invoice_email"
                          name="Invoice notifications email"
                          v-slot="context"
                        >
                          <b-form-group label="Invoice notifications email">
                            <b-form-input
                              id="formNotifyInvoiceEmail"
                              placeholder="e.g thorfinn@karlsefni.com"
                              v-model="notify_invoice_email"
                              :state="getValidationState(context)"
                              aria-describedby="formNotifyInvoiceEmailError"
                              v-bind="context.ariaInput"
                            />

                            <b-form-invalid-feedback id="formNotifyInvoiceEmailError" v-bind="context.ariaMsg">
                              {{ context.errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                      </b-card>

                      <hr />

                      <h4><i class="far fa-heading fa-fw"></i> Invoice Details</h4>
                      <b-card>
                        <validation-provider
                          vid="invoice_legal_name"
                          name="Legal Name"
                          v-slot="context"
                          rules="required"
                        >
                          <b-form-group label="Legal Name">
                            <b-form-input
                              id="formInvoiceLegalName"
                              placeholder="e.g Youwish Kundeservice AS"
                              v-model="invoice_legal_name"
                              :state="getValidationState(context)"
                              aria-describedby="formInvoiceLegalNameError"
                              v-bind="context.ariaInput"
                            />

                            <b-form-invalid-feedback id="formInvoiceLegalNameError" v-bind="context.ariaMsg">
                              {{ context.errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>

                        <validation-provider vid="invoice_org_nr" name="Org Number" v-slot="context" rules="required">
                          <b-form-group label="Org Number">
                            <b-form-input
                              id="formInvoiceOrgNumber"
                              placeholder="e.g 999 639 658"
                              v-model="invoice_org_nr"
                              :state="getValidationState(context)"
                              aria-describedby="formInvoiceOrgNumberError"
                              v-bind="context.ariaInput"
                            />

                            <b-form-invalid-feedback id="formInvoiceOrgNumberError" v-bind="context.ariaMsg">
                              {{ context.errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>

                        <validation-provider vid="invoice_bank_number" name="Bank Number" v-slot="context">
                          <b-form-group label="Bank Number">
                            <b-form-input
                              id="formInvoiceBankNumber"
                              placeholder="e.g 86011117947"
                              v-model="invoice_bank_number"
                              :state="getValidationState(context)"
                              aria-describedby="formInvoiceBankNumberError"
                              v-bind="context.ariaInput"
                            />

                            <b-form-invalid-feedback id="formInvoiceBankNumberError" v-bind="context.ariaMsg">
                              {{ context.errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>

                        <validation-provider vid="invoice_iban" name="IBAN" v-slot="context">
                          <b-form-group label="IBAN">
                            <b-form-input
                              id="formInvoiceIBAN"
                              placeholder="e.g NO9386011117947"
                              v-model="invoice_iban"
                              :state="getValidationState(context)"
                              aria-describedby="formInvoiceIBANError"
                              v-bind="context.ariaInput"
                            />

                            <b-form-invalid-feedback id="formInvoiceIBANError" v-bind="context.ariaMsg">
                              {{ context.errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>

                        <validation-provider vid="invoice_bic" name="BIC" v-slot="context">
                          <b-form-group label="BIC">
                            <b-form-input
                              id="formBIC"
                              placeholder="e.g DEUTDEFFXXX"
                              v-model="invoice_bic"
                              :state="getValidationState(context)"
                              aria-describedby="formBICError"
                              v-bind="context.ariaInput"
                            />

                            <b-form-invalid-feedback id="formBICError" v-bind="context.ariaMsg">
                              {{ context.errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>

                        <validation-provider vid="invoice_bank" name="Bank Name" v-slot="context">
                          <b-form-group label="Bank Name">
                            <b-form-input
                              id="formInvoiceBankName"
                              placeholder="e.g HSBC"
                              v-model="invoice_bank"
                              :state="getValidationState(context)"
                              aria-describedby="formInvoiceBankNameError"
                              v-bind="context.ariaInput"
                            />

                            <b-form-invalid-feedback id="formInvoiceBankNameError" v-bind="context.ariaMsg">
                              {{ context.errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>

                        <validation-provider
                          vid="invoice_payment_interval"
                          name="Payment Interval"
                          v-slot="context"
                          rules="required"
                        >
                          Invoice due date in days (ex: 14)
                          <b-form-group label="Payment Interval">
                            <b-form-input
                              id="formInvoicePaymentInterval"
                              placeholder="e.g DEUTDEFFXXX"
                              v-model="invoice_payment_interval"
                              :state="getValidationState(context)"
                              aria-describedby="formInvoicePaymentIntervalError"
                              v-bind="context.ariaInput"
                            />

                            <b-form-invalid-feedback id="formInvoicePaymentIntervalError" v-bind="context.ariaMsg">
                              {{ context.errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>

                        <validation-provider
                          vid="invoice_frequency"
                          name="Payment Frequency"
                          v-slot="context"
                          rules="required"
                        >
                          Generate invoice once or twice a month (enter 0 for once, 14 for twice)
                          <b-form-group label="Payment Frequency">
                            <b-form-input
                              id="formPaymentFrequency"
                              placeholder="e.g DEUTDEFFXXX"
                              v-model="invoice_frequency"
                              :state="getValidationState(context)"
                              aria-describedby="formPaymentFrequencyError"
                              v-bind="context.ariaInput"
                            />

                            <b-form-invalid-feedback id="formPaymentFrequencyError" v-bind="context.ariaMsg">
                              {{ context.errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>

                        <validation-provider vid="invoice_address" name="Address" v-slot="context" rules="required">
                          <b-form-group label="Address">
                            <b-form-textarea
                              id="formInvoiceAddress"
                              v-model="invoice_address"
                              :state="getValidationState(context)"
                              aria-describedby="formInvoiceAddressError"
                              v-bind="context.ariaInput"
                            />

                            <b-form-invalid-feedback id="formInvoiceAddressError" v-bind="context.ariaMsg">
                              {{ context.errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>

                        <validation-provider
                          vid="invoice_postal_code"
                          name="Postal Code"
                          v-slot="context"
                          rules="required"
                        >
                          <b-form-group label="Postal Code">
                            <b-form-input
                              id="formInvoicePostalCode"
                              placeholder="e.g 1234"
                              v-model="invoice_postal_code"
                              :state="getValidationState(context)"
                              aria-describedby="formInvoicePostalCodeError"
                              v-bind="context.ariaInput"
                            />

                            <b-form-invalid-feedback id="formInvoicePostalCodeError" v-bind="context.ariaMsg">
                              {{ context.errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>

                        <validation-provider vid="invoice_city" name="City" v-slot="context" rules="required">
                          <b-form-group label="City">
                            <b-form-input
                              id="formInvoiceCity"
                              placeholder="e.g Stockholm"
                              v-model="invoice_city"
                              :state="getValidationState(context)"
                              aria-describedby="formInvoiceCityError"
                              v-bind="context.ariaInput"
                            />

                            <b-form-invalid-feedback id="formInvoiceCityError" v-bind="context.ariaMsg">
                              {{ context.errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>

                        <validation-provider vid="country_code" name="Country Code" v-slot="context" rules="required">
                          <b-form-group label="Country Code">
                            <b-form-input
                              id="formInvoiceCountryCode"
                              placeholder="e.g NO"
                              v-model="country_code"
                              :state="getValidationState(context)"
                              aria-describedby="formInvoiceCountryCodeError"
                              v-bind="context.ariaInput"
                            />

                            <b-form-invalid-feedback id="formInvoiceCountryCodeError" v-bind="context.ariaMsg">
                              {{ context.errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>

                        <validation-provider vid="no_vat_on_invoice" name="No VAT on invoice">
                          <b-form-checkbox v-model="no_vat_on_invoice">
                            No VAT on invoice
                          </b-form-checkbox>
                        </validation-provider>
                      </b-card>

                      <hr />

                      <setting-toggle
                        title="Booking Date system visible in dash"
                        description="Enable to show booking-dates in supplier dashboard"
                        :status="dash_date_activated"
                        @change="dash_date_activated = !dash_date_activated"
                      />

                      <hr />

                      <setting-toggle
                        title="Send booking notification on new booking"
                        description="Enable Send booking notification on new booking"
                        :status="notify_on_new_booking"
                        @change="notify_on_new_booking = !notify_on_new_booking"
                      />

                      <hr />

                      <setting-toggle
                        title="Send booking notification when booking closes"
                        description="Enable Send booking notification when booking close"
                        :status="notify_on_booking_closed"
                        @change="notify_on_booking_closed = !notify_on_booking_closed"
                      />

                      <hr />

                          <b-button variant="primary" type="submit" :disabled="isLoading">
                            {{ buttonText }}
                            <b-spinner v-show="isLoading" class="ml-2" small label="Loading" />
                          </b-button>
                    </form>
                  </validation-observer>
                </b-tab>
                <b-tab
                  title="Users"
                  :active="$route.name === 'suppliers.users'"
                  :disabled="!isEditPage && $route.name !== 'suppliers.users'"
                  @click="tabGoTo({ name: 'suppliers.users', params: { id: $route.params.id } })"
                >
                  <supplier-users-page />
                </b-tab>
              </b-tabs>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import SupplierUsersPage from './SupplierUsersPage'
import { getValidationState } from './page-helpers'
import SettingToggle from '@/components/SettingToggle'

export default {
  name: 'SuppliersFormPage',
  components: { SupplierUsersPage, SettingToggle },
  data() {
    return {
      id: '',
      booking_name: '',
      booking_phone: '',
      booking_phone_time: '',
      booking_email: '',
      booking_address: '',
      booking_postal_code: '',
      booking_city: '',
      booking_url: '',
      booking_website_url: '',
      contact_name: '',
      contact_email: '',
      contact_phone: '',
      invoice_legal_name: '',
      invoice_org_nr: '',
      invoice_bank_number: '',
      invoice_bic: '',
      invoice_iban: '',
      invoice_bank: '',
      invoice_payment_interval: '',
      invoice_frequency: '',
      invoice_address: '',
      invoice_postal_code: '',
      invoice_city: '',
      country_code: '',
      no_vat_on_invoice: false,
      status: null,
      dash_date_activated: false,
      notify_on_new_booking: false,
      notify_on_booking_closed: false,
      notify_booking_email: '',
      notify_invoice_email: '',
      vat: 25,
      isLoading: false,
    }
  },
  beforeRouteEnter(from, to, next) {
    next(vm => vm.loadContent())
  },
  async mounted() {
    await this.loadContent()
  },
  computed: {
    isEditPage() {
      return this.$route.name === 'suppliers.edit'
    },
    pageTitle() {
      return this.isEditPage ? 'Edit Supplier' : 'New Supplier'
    },
    breadcrumbTitle() {
      return this.isEditPage ? 'EDIT' : 'NEW'
    },
    buttonText() {
      return `${this.isEditPage ? 'Save' : 'Create'} Supplier`
    },
  },
  methods: {
    getValidationState,
    async loadContent() {
      if (!this.isEditPage) return

      const supplier = await this.$store.dispatch('suppliers/find', this.$route.params.id)

      this.id = supplier.id
      this.booking_name = supplier.booking_name
      this.booking_phone = supplier.booking_phone
      this.booking_phone_time = supplier.booking_phone_time
      this.booking_email = supplier.booking_email
      this.booking_address = supplier.booking_address
      this.booking_postal_code = supplier.booking_postal_code
      this.booking_city = supplier.booking_city
      this.booking_url = supplier.booking_url
      this.booking_website_url = supplier.booking_website_url
      this.contact_name = supplier.contact_name
      this.contact_email = supplier.contact_email
      this.contact_phone = supplier.contact_phone
      this.invoice_legal_name = supplier.invoice_legal_name
      this.invoice_org_nr = supplier.invoice_org_nr
      this.invoice_bank_number = supplier.invoice_bank_number
      this.invoice_bic = supplier.invoice_bic
      this.invoice_iban = supplier.invoice_iban
      this.invoice_bank = supplier.invoice_bank
      this.invoice_payment_interval = supplier.invoice_payment_interval
      this.invoice_frequency = supplier.invoice_frequency
      this.invoice_address = supplier.invoice_address
      this.invoice_postal_code = supplier.invoice_postal_code
      this.invoice_city = supplier.invoice_city
      this.country_code = supplier.country_code
      this.no_vat_on_invoice = supplier.no_vat_on_invoice
      this.status = supplier.status
      this.dash_date_activated = supplier.dash_date_activated
      this.vat = supplier.vat
      ;(this.notify_on_new_booking = supplier.notify_on_new_booking),
        (this.notify_on_booking_closed = supplier.notify_on_booking_closed),
        (this.notify_booking_email = supplier.notify_booking_email),
        (this.notify_invoice_email = supplier.notify_invoice_email)
    },
    submitFormData() {
      const data = {
        booking_name: this.booking_name,
        booking_phone: this.booking_phone,
        booking_phone_time: this.booking_phone_time,
        booking_email: this.booking_email,
        booking_address: this.booking_address,
        booking_postal_code: this.booking_postal_code,
        booking_city: this.booking_city,
        booking_url: this.booking_url,
        booking_website_url: this.booking_website_url,
        contact_name: this.contact_name,
        contact_email: this.contact_email,
        contact_phone: this.contact_phone,
        invoice_legal_name: this.invoice_legal_name,
        invoice_org_nr: this.invoice_org_nr,
        invoice_bank_number: this.invoice_bank_number,
        invoice_bic: this.invoice_bic,
        invoice_iban: this.invoice_iban,
        invoice_bank: this.invoice_bank,
        invoice_payment_interval: this.invoice_payment_interval,
        invoice_frequency: this.invoice_frequency,
        invoice_address: this.invoice_address,
        invoice_postal_code: this.invoice_postal_code,
        invoice_city: this.invoice_city,
        country_code: this.country_code,
        no_vat_on_invoice: this.no_vat_on_invoice,
        status: this.status ?? 'active',
        dash_date_activated: this.dash_date_activated,
        vat: this.vat,
        notify_on_new_booking: this.notify_on_new_booking,
        notify_on_booking_closed: this.notify_on_booking_closed,
        notify_booking_email: this.notify_booking_email,
        notify_invoice_email: this.notify_invoice_email,
      }

      this.isLoading = true
      if (this.isEditPage) {
        this.handleSupplierUpdate({ ...data, id: this.id })
      } else {
        this.handleSupplierCreate(data)
      }
    },
    async handleSupplierCreate(payload) {
      try {
        await this.$store.dispatch('suppliers/create', payload)
        this.$bvToast.toast(`Supplier details "${this.booking_name}" created.`, {
          title: 'Supplier created',
          variant: 'primary',
        })
        this.$router.push({name: 'suppliers'});
      } catch (error) {
        this.$refs.supplierForm.setErrors(error.errors)
        this.$bvToast.toast(`The changes could not be saved. Please review and resolve errors before trying again.`, {
          title: 'Supplier not created',
          variant: 'danger',
        })
      }
      this.isLoading = false
    },
    async handleSupplierUpdate(payload) {
      try {
        this.isLoading = true
        await this.$store.dispatch('suppliers/update', payload)
        this.$bvToast.toast(`Supplier details "${this.booking_name}" updated.`, {
          title: 'Supplier updated',
          variant: 'primary',
        })
      } catch (error) {
        this.$refs.supplierForm.setErrors(error.errors)
        this.$bvToast.toast(`The changes could not be saved. Please review and resolve errors before trying again.`, {
          title: 'Supplier not updated',
          variant: 'danger',
        })
      }
      this.isLoading = false
    },
    tabGoTo(route) {
      if (route.name !== this.$route.name) {
        this.loadContent()

        this.$router.push(route)
      }
    },
  },
}
</script>
