<template>
  <div class="pt-3">
    <b-row align-v="center">
      <b-col>
        <h5>Supplier Users</h5>
      </b-col>
      <b-col cols="auto">
        <b-button v-b-modal="'add_user'" variant="primary">Add User</b-button>

        <b-modal id="add_user" title="Supplier User" v-slot="{ ok }" hide-footer>
          <supplier-users-form @added="onUserAdded(ok)" />
        </b-modal>
      </b-col>
    </b-row>
    <hr />
    <b-card>
      <b-table :fields="fields" :items="users.data()" show-empty stacked="sm" bordered hover>
        <template v-slot:cell(options)="{ item }">
          <b-dropdown size="sm" text="Options" variant="default">
            <b-dropdown-item @click="impersonate(item)">
              Impersonate
            </b-dropdown-item>
            <b-modal :id="`not-impersonate-${item.id}`" title="Impersonation Forbidden" ok-only>
              This user is an administrator, as such impersonation is strictly forbidden.
            </b-modal>
            <b-dropdown-item v-b-modal="`edit-permissions-${item.id}`">
              Edit Permissions
            </b-dropdown-item>
            <b-dropdown-divider />
            <b-dropdown-item v-b-modal="`remove-user-${item.id}`">
              Remove
            </b-dropdown-item>

            <b-modal :id="`edit-permissions-${item.id}`" title="Edit Supplier User" v-slot="{ ok }" hide-footer>
              <supplier-users-form :connection="item" @updated="onUserUpdated(ok)" />
            </b-modal>

            <b-modal :id="`remove-user-${item.id}`" title="Remove User" @ok="removeUser(item)">
                <div class="text-center">
                    <strong>Are you sure you want to remove user</strong>
                    <p><i>{{ item.user.email }}</i> from supplier <i>{{ item.supplier.booking_name }}</i>?</p>
                </div>
            </b-modal>
          </b-dropdown>
        </template>
      </b-table>
      <hr />
      <h4>Invited users <small><b-spinner v-if="isLoading" class="ml-2" small label="Loading" /></small></h4>
      <b-table :fields="invitedFields" :items="invites.data()" show-empty stacked="sm" bordered hover>
        <template v-slot:cell(options)="{ item }">
          <b-dropdown size="sm" text="Options" variant="default">
            <b-dropdown-item @click="resendNotification(item.email)">Resend invitation</b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>

      <!-- pagination -->
      <b-pagination
        v-if="meta.total > meta.per_page"
        v-model="meta.current_page"
        :total-rows="meta.total"
        :per-page="meta.per_page"
        @change="paginationChange"
      />
    </b-card>
  </div>
</template>

<script>
import {suppliers as suppliersApi, suppliers, users} from '@/api'
import SupplierUsersForm from './SupplierUsersForm'

export default {
  name: 'SupplierUsersPage',
  components: { SupplierUsersForm },
  beforeMount() {
    this.loadUsers()
  },
  data() {
    return {
      users: [],
      invites: [],
      meta: {
        current_page: 1,
        from: 0,
        last_page: 1,
        path: '',
        per_page: 0,
        to: 0,
        total: 0,
      },
      isLoading: false,
    }
  },
  computed: {
    fields() {
      return [
        { key: 'id', label: 'ID' },
        { key: 'user', formatter: user => user.email },
        { key: 'options', label: '' },
      ]
    },
    invitedFields() {
      return [{ key: 'email' }, { key: 'options', label: '' }]
    },
  },
  methods: {
    async loadUsers() {
      this.users = await suppliers.users().all(this.$route.params.id)
      this.invites = await suppliers.users().invites(this.$route.params.id)
    },
    paginationChange(page) {
      this.$router.push(page === 1 ? { name: 'supplier.users' } : { name: 'supplier.users', query: { page } })

      this.loadContent()
    },
    async resendNotification(email) {
        this.isLoading = true
        try {
            await suppliersApi.invite(this.$route.params.id, {
                email: email,
                invite_new: false
            })
            this.$bvToast.toast(`User re-invited.`, {
                title: 'User re-invited',
                variant: 'primary',
            })
            this.$emit('added')
        } catch (error) {
            this.error = error.message
        }
        this.isLoading = false
    },
    async impersonate(user) {
      try {
        await users.impersonate(user.user.id)

        window.open(process.env.VUE_APP_SUPPLIER_DASHBOARD_URL, '_blank').focus()
      } catch (error) {
        if (error.code === 'request.forbidden') {
          this.$bvModal.show(`not-impersonate-${user.id}`)
        }
      }
    },
    async onUserAdded(done) {
      done()
      this.$bvToast.toast(`User was successfully added to supplier`, {
        title: 'New User',
        variant: 'primary',
      })
      await this.loadUsers()
    },
    onUserUpdated(done) {
      done()
      this.$bvToast.toast(`User permissions successfully updated.`, {
        title: 'Permissions updated',
        variant: 'primary',
      })
    },
    async removeUser(item) {
      await suppliers.users().remove(item.id)
      this.loadUsers()
      this.$bvToast.toast(`"${item.user.email}" was successfully removed from supplier`, {
        title: 'User Removed',
        variant: 'primary',
      })
    },
  },
}
</script>
